import React from 'react';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ppx from '../components/ppx';
import DataBox from '../components/dataBox';
import Slides from '@ifeng/ui_pc_slides';
import Chip from 'Chip';
import PpLive from './ppLive';
import SlideImg from './slideImg';
import { rel } from '@Container/common/client/utils/rel';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const {
            ppxAd: { error_ppposition_live1 },
            topicPpxAd,
            hourRank,
            todayRank,
            footer,
            slideImgs,
        } = content;

        const hourRankData = {
            title: '48小时点击排行',
            data: hourRank,
        };

        const todayRankData = {
            title: '本日热榜排行',
            data: todayRank,
        };

        const copyrightData = footer.pop();

        return (
            <div>
                <div className={styles.col}>
                    <div className={styles.ifengLogo}>
                        <a href="//www.ifeng.com" title="凤凰网">
                            <span />
                        </a>
                    </div>
                    {topicPpxAd ? (
                        <Ppx content={topicPpxAd} />
                    ) : (
                        <div className={styles.topic}>
                            <a href="//gongyi.ifeng.com/xianfeng/special/clist_0/1.shtml" title="公益先锋">
                                <span />
                            </a>
                        </div>
                    )}
                </div>
                <div className={styles.col}>
                    {slideImgs ? (
                        <Chip
                            id="75146"
                            type="recommend"
                            title="轮播图"
                            groupName="轮播"
                            position="relative"
                            content={slideImgs}
                            translate="">
                            <SlideImg />
                        </Chip>
                    ) : (
                        ''
                    )}

                    <div className={styles.tip404}>
                        <h1 className={styles.img404}>
                            <span />
                        </h1>
                        <h1 className={styles.tip}>
                            抱歉-您访问的页面不存在！
                            <br />
                            <span>Sorry-We cannot find the page you requested！</span>
                        </h1>
                        <ul>
                            <li>输入的网址不正确</li>
                            <li>页面已被删除</li>
                        </ul>
                        <h4>
                            <a href="//help.ifeng.com" title="帮忙改进问题">
                                <span />
                            </a>
                        </h4>
                    </div>
                </div>
                <div className={styles.dataBox}>
                    <div className={styles.boxL}>
                        <DataBox content={hourRankData} />
                    </div>
                    <div className={styles.boxR}>
                        <DataBox content={todayRankData} />
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={`${styles.foot_link} clearfix`}>
                        <div className={styles.footL}>
                            <div className={styles.list}>
                                {footer.map((item, index) => (
                                    <a key={index} className={styles.link} href={item.url} target="_blank" rel={rel}>
                                        {item.title}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className={styles.footR}>
                            <ul className="clearfix">
                                <li className={styles.bg001}>
                                    <a href="//v.ifeng.com/" target="_blank" rel={rel} title="视频">
                                        视频
                                    </a>
                                </li>
                                <li>
                                    ·<a
                                        href="//v.ifeng.com/documentary/index.shtml"
                                        target="_blank"
                                        rel={rel}
                                        title="纪实">
                                        纪实
                                    </a>
                                </li>
                                <li>
                                    ·<a href="//v.ifeng.com/live/" target="_blank" rel={rel} title="直播">
                                        直播
                                    </a>
                                </li>
                                <li className={styles.bg002}>
                                    <a href="//phtv.ifeng.com/" target="_blank" rel={rel} title="凤凰卫视">
                                        凤凰卫视
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.copyright}>
                        <span>{copyrightData.text0}</span>
                        <span>{copyrightData.text1}</span>
                        <span>{copyrightData.text2}</span>
                    </div>
                </div>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default Layout;
