try {
    if (typeof window.PPX_analytics !== 'undefined' && typeof window.PPX_analytics.send === 'function') {
        window.PPX_analytics.init({});
    }
} catch (e) {
    console.error(e);
}

const paddingZero = i => {
    if (i < 10) {
        i = `0${i}`;
    }

    return i;
};

const formatTime = timestamp => {
    const date = new Date(timestamp);
    const Y = `${date.getFullYear()}-`;
    const M = `${paddingZero(date.getMonth() + 1)}-`;
    const D = `${paddingZero(date.getDate())}+`;
    const h = `${paddingZero(date.getHours())}:`;
    const m = `${paddingZero(date.getMinutes())}:`;
    const s = `${paddingZero(date.getSeconds())}`;

    return Y + M + D + h + m + s;
};

const getSession = (anchorId, position, liveId) => {
    // 兼容IE
    if (!window.location.origin) {
        window.location.origin = `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ''
        }`;
    }

    const _timestamp = formatTime(new Date().getTime());
    const url = window.location.href;
    const pageInfo = `${encodeURIComponent(liveId)}:${encodeURIComponent(position)}:${encodeURIComponent(
        url,
    )}:${encodeURIComponent(anchorId)}`;
    const sess = `${_timestamp}#pageinfo#pinfo=${encodeURIComponent(pageInfo)}`;

    return sess;
};

const pageToSendSta = (tag, sessionData) => {
    const getSessionToSend = sessionData => {
        let sessionStr = '';

        for (const _liveData of sessionData) {
            sessionStr = `${sessionStr === '' ? '' : `${sessionStr}@`}${getSession(
                _liveData.anchorId,
                tag,
                _liveData.liveId,
            )}`;
        }

        return sessionStr;
    };
    // 获取各个瞄点需要发送的session信息
    const sessionToSend = getSessionToSend(sessionData);

    if (typeof window.PPX_analytics !== 'undefined' && typeof window.PPX_analytics.send === 'function') {
        window.PPX_analytics.send(sessionToSend);
    }
};

const pageToSendFloat = (tag, _liveData) => {
    const sessionStr = getSession(_liveData.anchorId, tag, _liveData.liveId);

    if (typeof window.PPX_analytics !== 'undefined' && typeof window.PPX_analytics.send === 'function') {
        window.PPX_analytics.send(sessionStr);
    }
};

export { pageToSendSta, pageToSendFloat };
