import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng/errorBoundary';
import Slides from '@ifeng/ui_pc_slides';

class SlideImg extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    sliderTmpl = list => {};

    render() {
        const { content } = this.props;

        if (content.length === 0) return <div />;

        const config = {
            arrows: false,
            dots: true,
            dotsAction: 'hover',
            autoplay: true,
            autoplayInterval: 6000,
            speed: 1000,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
        };

        return (
            <div className={styles.pao_mid}>
                <div className={styles.box}>
                    <Slides content={content} config={config} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(SlideImg);
